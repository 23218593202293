import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import { setTime } from "@/utils/auth";
import { login, login_ht, getConfig, indexout } from '@/api/user'
Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  //登陆页面
  {
    path: "/",
    name: 'loginas',
    component: () => import('../views/login/login_a.vue'),

  },
  //注册页面
  {
    path: '/zuce',
    name: 'zuce',
    component: () => import('../views/login/login_zuce.vue'),
  },
  //一级路由
  {
    path: '/index',
    // name:'index',
    component: () => import('../App_index.vue'),
    children: [
      //个人资料页面
      {
        path: "/account",
        name: 'account',
        component: () => import('../views/accuser/xiugai.vue'),

      },
      //个人资料页面
      {
        path: "/personal",
        name: 'personal',
        component: () => import('../views/accuser/geren.vue'),

      },
      //控制台
      {
        path: "kzt",
        name: "kongzhitai",
        component: () => import('../views/user/index_home.vue'),

      },
      //国内发货
      {
        path: "guonei",
        name: "guoneifahuo",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 订单列表
          {
            path: 'gnlist',
            name: 'gnlist',
            component: () => import('../views/guonei/gnddlist.vue'),

          },
          {
            path: 'gnlist1',
            name: 'gnlist1',
            component: () => import('../views/guonei/gnddlist1.vue'),

          },
          // 预报运单
          {
            path: 'yundan',
            name: 'gnwaybill',
            component: () => import('../views/guonei/gncarate_waybill.vue'),

          },
          // 批量上传
          {
            path: 'pilsc',
            name: 'gnpilsc',
            component: () => import('../views/guonei/gnpiliang.vue'),

          },
          {
            path: '*',
            redirect: "/index/guonei/gnlist"
          }
        ]

      },
      // 专线小包
      {
        path: "zxxb",
        name: "zxxb",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 订单列表
          {
            path: 'zlist',
            name: 'zlist',
            component: () => import('../views/xiaobao/gnddlist.vue'),

          },
          // 订单列表
          {
            path: 'zlist1',
            name: 'zlist1',
            component: () => import('../views/xiaobao/gnddlist1.vue'),

          },
          // 创建运单
          {
            path: 'zwaybill',
            name: 'zwaybill',
            component: () => import('../views/xiaobao/gncarate_waybill.vue'),

          },
          // 批量上传
          {
            path: 'zpilsc',
            name: 'zpilsc',
            component: () => import('../views/xiaobao/gnpiliang.vue'),

          },
          // 入仓单管理
          {
            path: 'rcdgl',
            name: 'rcdgl',
            component: () => import('../views/xiaobao/xbrucangprint.vue'),

          },
          {
            path: '*',
            redirect: "/index/kuaidi/kdlist"
          }
        ]
      },
      // 快递打单
      {
        path: "kuaidi",
        name: "kuaididingdan",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 订单列表
          {
            path: 'kdlist',
            name: 'ddlist',
            component: () => import('../views/order/ding_list.vue'),

          },
          // 订单列表
          {
            path: 'kdlist1',
            name: 'ddlist1',
            component: () => import('../views/order/ding_list1.vue'),

          },
          // 创建运单
          {
            path: 'yundan',
            name: 'waybill',
            component: () => import('../views/order/create_waybill.vue'),

          },
          // 批量上传
          {
            path: 'pilsc',
            name: 'pilsc',
            component: () => import('../views/order/piliang.vue'),

          },
          // 打单相关
          {
            path: 'ddxg',
            name: 'ddxg',
            component: () => import('../views/order/dadanxiangguan.vue'),

          },
          // 快递SF
          {
            path: 'kdsf',
            name: 'kdsf',
            component: () => import('../views/order/ksSF.vue'),

          },
          {
            path: '*',
            redirect: "/index/kuaidi/kdlist"
          }
        ]
      },

      //海外发货
      {
        path: "haiwai",
        name: "haiwaifahuo",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 订单列表
          {
            path: 'hwddlist',
            name: 'hwddlist',
            component: () => import('../views/haiwai/hwddlilst.vue'),

          },
          // 订单列表
          {
            path: 'hwddlist1',
            name: 'hwddlist1',
            component: () => import('../views/haiwai/hwddlilst1.vue'),

          },
          // 自传面单
          {
            path: 'zcmd',
            name: 'zcmd',
            component: () => import('../views/haiwai/zichuanmiandan.vue'),
          },
          // 预报订单
          {
            path: 'hwybdd',
            name: 'hwybdd',
            component: () => import('../views/haiwai/hwcarate_waybill.vue'),

          },
          // 批量上传
          {
            path: 'hwplsc',
            name: 'hwplsc',
            component: () => import('../views/haiwai/hwpiliang.vue'),

          },
          //库存管理
          {
            path: 'xjrk',
            name: 'xjrk',
            component: () => import('../views/haiwai/servercenter/skugl.vue'),

          },
          //入库记录
          {
            path: 'rkjl',
            name: 'rkjl',
            component: () => import('../views/haiwai/rukujilu.vue'),

          },
          //代收仓租
          {
            path: 'dscz',
            name: 'dscz',
            component: () => import('../views/haiwai/daishouCz.vue'),

          },
          // 海外SF
          {
            path: 'hwsf',
            name: 'hwsf',
            component: () => import('../views/haiwai/hwSF.vue'),

          },
          {
            path: 'skugl',
            name: 'skugl',
            component: () => import('../views/haiwai/skugl.vue'),

          },
          {
            path: 'xstj',
            name: 'xstj',
            component: () => import('../views/haiwai/xstj.vue'),

          },
          {
            path: '*',
            redirect: "/index/haiwai/hwddlist"
          }

        ]
      },

      //平台导入
      {
        path: "pingtai",
        name: "ptdr",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 平台账号
          {
            path: 'ptzh',
            name: 'ptzh',
            component: () => import('@/views/pingtaidaoru/ptzh.vue'),

          },
          // 临时订单列表
          {
            path: 'lsddlb',
            name: 'lsddlb',
            component: () => import('@/views/pingtaidaoru/lsddList.vue'),

          },
          // SKU
          {
            path: 'skugx',
            name: 'skugx1',
            component: () => import('@/views/pingtaidaoru/skugx.vue'),

          },

          {
            path: '*',
            redirect: "/index/pingtai/ptzh"
          }

        ]
      },
      //财务中心
      {
        path: "caiwu",
        name: "caiwuzhongxin",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          // 财务流水
          {
            path: 'caiwuliu',
            name: 'caiwuls',
            component: () => import('@/views/caiwu/cwliusui.vue'),

          },
          // 大货账单
          {
            path: 'dhzd',
            name: 'dhzd',
            component: () => import('@/views/caiwu/dhzdlist.vue'),

          },
          // 小包账单
          {
            path: 'xbzd',
            name: 'xbzd',
            component: () => import('@/views/caiwu/xbaozdlist.vue'),

          },
          // 财务报表
          // {
          //   path:'caiwubaob',
          //   name:'caiwubaob',
          //   component:()=>import('@/views/caiwu/caiwulist.vue'),

          // },
          {
            path: '*',
            redirect: "/index/caiwu/caiwuliu"
          }

        ]
      },
      //系统设置
      {
        path: "xtsz",
        name: "syssettings",
        component: () => import('@/views/order/indexview.vue'),
        children: [
          //地址管理
          {
            path: 'ddgl',
            name: 'ddgl',
            component: () => import('@/views/shezhi/ddgl.vue'),
          },
          // 渠道价格
          {
            path: 'ddglu',
            name: 'qdjg',
            component: () => import('@/views/shezhi/qudaopice.vue'),
          },
          // 工单
          {
            path: 'gdlb',
            name: 'gdlb',
            component: () => import('@/views/shezhi/workOrder.vue'),
          },
          {
            path: 'xtpz',
            name: 'xtpz',
            component: () => import('@/views/shezhi/xitongpz.vue'),
          },
          {
            path: 'smdd',
            name: 'smdd',
            component: () => import('@/views/shezhi/saomashezhi.vue'),
          },
          {
            path: 'dcsz',
            name: 'dcsz',
            component: () => import('@/views/shezhi/dcshezhi.vue'),
          },
          {
            path: '*',
            redirect: "/index/xtsz/ddgl"
          }
        ]

      },


    ]
  },
  //试算价格
  {
    path: '/Trialpricecalculation',
    name: 'Trialpricecalculation',
    component: () => import('../views/login/Trialpricecalculation.vue'),
  },
  {
    path: '*',
    redirect: "/index/kzt"
  }


]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




// 定义白名单
router.beforeEach(async (to, from, next) => {
  const whiteList = ['/', '/zuce', '/Trialpricecalculation']
  // 定义虚拟登陆
  if (to.query.moni == 1) {
    localStorage.removeItem('token')
    let data = {
      uname: to.query.name,
      pass: to.query.password,
      hdid: to.query.hdid,
      moni: to.query.moni,
    }
    let res = await login_ht(data)
    if (res.code == 200) {
      window.sessionStorage.setItem("token", res.data.token)
      window.localStorage.setItem("token", res.data.token)

      let user = await indexout()
      window.localStorage.setItem('menu', JSON.stringify(user.data.menu))

      window.sessionStorage.setItem('active', 1)
    } else {
      Message({
        type: 'error',
        message: res.msg
      });
    }
  } else if (to.query.moni == 2) {
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }
  let mena = window.localStorage.getItem('menu')
  let user = window.localStorage.getItem('setuser')
  if (user) {
    store.commit('setuserdata', user)
  }
  if (mena) {
    store.commit('setstremenu', mena)
  }


  //路由跳转之前
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')



  // 无token下拦截(去往的页面不在白名单，并且没有token，则返回登录页)
  if (whiteList.indexOf(to.path) == -1 && !token) {
    return next('/')
  }
  // 有token下拦截(去往白名单页面，则直接返回首页)
  if (whiteList.indexOf(to.path) != -1 && token) {

    return next()
  }
  next()
});
export default router
